<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <h4 class="font-semibold mb-2">
          {{ $t(resources.Detail.i18n) || resources.Detail.name }} -
          {{
          $t(resources.TipAndCustomizedFee.i18n) ||
          resources.TipAndCustomizedFee.name
          }}
        </h4>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/4 mb-2">
            <vs-select
              class="vx-col w-full"
              :label="$t(resources.Type.i18n) || resources.Type.name"
              v-model="chargeType"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in chargeTypeList"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-2">
            <vs-select
              class="vx-col w-full"
              :label="$t(resources.Status.i18n) || resources.Status.name"
              v-model="status"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in statusList"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
            <vs-button color="primary" type="filled" @click="getTipAndFee()">
              {{
              $t(resources.Search.i18n) || resources.Search.name
              }}
            </vs-button>
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                  tipFeeData.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : tipFeeData.length
                  }}
                  of {{ tipFeeData.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="vx-row">
            <div class="mt-2">
              <vs-icon
                icon-pack="material-icons"
                icon="check_circle"
                size="small"
                color="rgba(var(--vs-success), 1)"
              ></vs-icon>
              {{ $t(resources.Paid.i18n) || resources.Paid.name }}&nbsp;
            </div>
            <div class="mt-2">
              <vs-icon
                icon-pack="material-icons"
                icon="highlight_off"
                size="small"
                color="rgba(var(--vs-danger), 1)"
              ></vs-icon>
              {{ $t(resources.WithoutPay.i18n) || resources.WithoutPay.name }}
            </div>
          </div>
          <!-- <div class="mb-4"> -->

          <!-- </div> -->

          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{
              $t(resources.ExportAs.i18n) || resources.ExportAs.name
              }}
              CVS
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="tipFeeData"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />

        <vs-row vs-type="flex" vs-justify="flex-end">
          <!-- Descriptions -->
          <vs-button
            color="rgb(174,174,174)"
            type="filled"
            @click="$router.push('/commissions/Customized')"
            class="mt-2"
          >{{ $t(resources.Return.i18n) || resources.Return.name }}</vs-button>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    Datepicker
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      start_date: null,
      end_date: null,
      status: "",
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      statusList: [
        {
          text: "All",
          value: "",
          i18n: "All"
        },
        {
          text: "Paid",
          value: "1",
          i18n: "Paid"
        },
        {
          text: "Without pay",
          value: "0",
          i18n: "WithoutPay"
        }
      ],
      chargeType: "",
      chargeTypeList: [
        {
          text: "All",
          value: "",
          i18n: "All"
        },
        {
          text: "Tip",
          value: "1",
          i18n: "Tip"
        },
        {
          text: "Customized fee",
          value: "2",
          i18n: "CustomizedFee"
        },
        {
          text: "Tip adjustment",
          value: "3",
          i18n: "AdjustmentTip"
        },
        {
          text: "Customized fee adjustment",
          value: "4",
          i18n: "AdjustmentCustomizedFee"
        },
        {
          text: "Pending charges",
          value: "5",
          i18n: "PendingCharges"
        }
      ],
      tipFeeData: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Type"),
          field: "TipoPropina",
          filter: true,
          cellRenderer: function(event) {
            return getDescriptionCharge(event.node.data.TipoCargo);
          }
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "EstaPago",
          width: 120,
          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.EstaPago) {
              case 0:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case 1:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              case 2:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">loop</i>';
                break;
              default:
                break;
            }
            return object;
          }
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "TipoReserva",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("BookingCode"),
          field: "Localizador",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Subtotal"),
          field: "Total",
          filter: true
        },
        {
          headerName: this.$i18n.t("FinancialCharge"),
          field: "ValorMerchant",
          filter: true
        },
        {
          headerName: this.$i18n.t("Total"),
          field: "Valor",
          filter: true
        },
        {
          headerName: this.$i18n.t("Customer"),
          field: "NombreCliente",
          filter: true
        },
        {
          headerName: this.$i18n.t("TransactionNumber"),
          field: "IdTransaccion",
          filter: true
        },
        {
          headerName: this.$i18n.t("PaymentDate"),
          field: "FechaPago",
          filter: true
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "FechaCreacion",
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created: function() {
    this.getTipAndFee();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getTipAndFee() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getTipAndFee",
        data: {
          distid: this.itaCode,
          state: this.status,
          chargeType: this.chargeType
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          if (result.data.rowsAffected[0] == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.tipFeeData = [];
          } else {
            this.tipFeeData = result.data.recordsets[0];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};

import VueI18n from "vue-i18n";
import i18nData from "../../i18n/i18nData.js";

const i18n = new VueI18n({
  locale: localStorage.getItem("language"), // set locale
  messages: i18nData // set locale messages
});
function getDescriptionCharge(type) {
  let value = i18n.t("Unclassified");
  switch (type) {
    case 1:
      value = i18n.t("Tip");
      break;
    case 2:
      value = i18n.t("CustomizedFee");
      break;
    case 3:
      value = i18n.t("AdjustmentTip");
      break;
    case 4:
      value = i18n.t("AdjustmentCustomizedFee");
      break;
    case 5:
      value = i18n.t("PendingCharges");
      break;
    default:
      break;
  }
  return value;
}
</script>
