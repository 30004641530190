var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "ag-grid-demo" } },
      [
        _c(
          "vx-card",
          [
            _c("h4", { staticClass: "font-semibold mb-2" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(_vm.resources.Detail.i18n) ||
                      _vm.resources.Detail.name
                  ) +
                  " -\n        " +
                  _vm._s(
                    _vm.$t(_vm.resources.TipAndCustomizedFee.i18n) ||
                      _vm.resources.TipAndCustomizedFee.name
                  ) +
                  "\n      "
              )
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/4 mb-2" },
                [
                  _c(
                    "vs-select",
                    {
                      staticClass: "vx-col w-full",
                      attrs: {
                        label:
                          _vm.$t(_vm.resources.Type.i18n) ||
                          _vm.resources.Type.name
                      },
                      model: {
                        value: _vm.chargeType,
                        callback: function($$v) {
                          _vm.chargeType = $$v
                        },
                        expression: "chargeType"
                      }
                    },
                    _vm._l(_vm.chargeTypeList, function(item, index) {
                      return _c("vs-select-item", {
                        key: index,
                        attrs: {
                          value: item.value,
                          text: _vm.$t(item.i18n) || item.text
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/4 mb-2" },
                [
                  _c(
                    "vs-select",
                    {
                      staticClass: "vx-col w-full",
                      attrs: {
                        label:
                          _vm.$t(_vm.resources.Status.i18n) ||
                          _vm.resources.Status.name
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    _vm._l(_vm.statusList, function(item, index) {
                      return _c("vs-select-item", {
                        key: index,
                        attrs: {
                          value: item.value,
                          text: _vm.$t(item.i18n) || item.text
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function($event) {
                          return _vm.getTipAndFee()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(_vm.resources.Search.i18n) ||
                              _vm.resources.Search.name
                          ) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between items-center" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                  },
                  [
                    _c(
                      "vs-dropdown",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { "vs-trigger-click": "" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                          },
                          [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.currentPage * _vm.paginationPageSize -
                                      (_vm.paginationPageSize - 1)
                                  ) +
                                  "\n                -\n                " +
                                  _vm._s(
                                    _vm.tipFeeData.length -
                                      _vm.currentPage * _vm.paginationPageSize >
                                      0
                                      ? _vm.currentPage * _vm.paginationPageSize
                                      : _vm.tipFeeData.length
                                  ) +
                                  "\n                of " +
                                  _vm._s(_vm.tipFeeData.length) +
                                  "\n              "
                              )
                            ]),
                            _c("feather-icon", {
                              attrs: {
                                icon: "ChevronDownIcon",
                                svgClasses: "h-4 w-4"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "vs-dropdown-menu",
                          [
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(20)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("20")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(50)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("50")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      100
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("100")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      150
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("150")])]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("vs-icon", {
                        attrs: {
                          "icon-pack": "material-icons",
                          icon: "check_circle",
                          size: "small",
                          color: "rgba(var(--vs-success), 1)"
                        }
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(_vm.resources.Paid.i18n) ||
                              _vm.resources.Paid.name
                          ) +
                          " \n          "
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("vs-icon", {
                        attrs: {
                          "icon-pack": "material-icons",
                          icon: "highlight_off",
                          size: "small",
                          color: "rgba(var(--vs-danger), 1)"
                        }
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(_vm.resources.WithoutPay.i18n) ||
                              _vm.resources.WithoutPay.name
                          ) +
                          "\n          "
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                  },
                  [
                    _c("vs-input", {
                      staticClass: "mb-4 md:mb-0 mr-4",
                      attrs: {
                        placeholder:
                          _vm.$t(_vm.resources.Search.i18n) ||
                          _vm.resources.Search.name
                      },
                      on: { input: _vm.updateSearchQuery },
                      model: {
                        value: _vm.searchQuery,
                        callback: function($$v) {
                          _vm.searchQuery = $$v
                        },
                        expression: "searchQuery"
                      }
                    }),
                    _c(
                      "vs-button",
                      {
                        staticClass: "mb-4 md:mb-0",
                        on: {
                          click: function($event) {
                            return _vm.gridApi.exportDataAsCsv()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.ExportAs.i18n) ||
                                _vm.resources.ExportAs.name
                            ) +
                            "\n            CVS\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("ag-grid-vue", {
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                gridOptions: _vm.gridOptions,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.tipFeeData,
                rowSelection: "single",
                colResizeDefault: "shift",
                animateRows: true,
                floatingFilter: true,
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true
              }
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            }),
            _c(
              "vs-row",
              { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mt-2",
                    attrs: { color: "rgb(174,174,174)", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/commissions/Customized")
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Return.i18n) ||
                          _vm.resources.Return.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }